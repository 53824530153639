import "./App.css";
import pokeLogo from "./images/poke&coFreshFood-large.png";
import igLogo from "./images/ig-logo.png";
import fbLogo from "./images/fb-logo.png";

function App() {
  return (
    <div className="App">
      <div className="centering">
        <div className="logo-container">
          <img src={pokeLogo} className="poke-logo" alt="poke-logo"></img>
        </div>
        <div className="text-container">
          <div className="text-it">
            Grazie mille a tutti per questa estate fantastica. Ci vediamo il prossimo anno!
          </div>
          <div className="text-en">
            Thank you all for this fantastic summer. See you next year!
          </div>
          <div className="social-logos">
            <a
              href="https://www.instagram.com/pokeco.lignanosabbiadoro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-logo" alt="FB logo" src={igLogo} />
            </a>
            <a
              href="https://www.facebook.com/PokeLignanoSabbiadoro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-logo" alt="IG logo" src={fbLogo} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
